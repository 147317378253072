.projects__title {
  margin-bottom: 15px;
}
.projects__msg {
  display: block;
  margin-bottom: 20px;

  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  @include breakpoint-min($desktop) {
    margin-bottom: 24px;

    font-size: 16px;
  }
}

.projects__list {
  @extend %cardSet;
  --gap: 10px;

  @include breakpoint-min($desktop) {
    --gap: 20px;
  }
}

.project {
  @extend %cardSet__item;

  @include breakpoint-min($desktop) {
    --items: 3;
  }
}
.project__link {
  position: relative;

  @extend %flex-afe;
  gap: 20px;
  width: 100%;
  height: 200px;
  padding: 20px;

  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  // background: linear-gradient( 180deg, rgba(0, 0, 0, 0) 65.45%, rgba(0, 0, 0, 0.64) 89.5% ), var(--background-url);
  // background-color: #000;
  // background-repeat: no-repeat;
  // background-size: cover;
  @extend %border-radius;
  overflow: hidden;

  &::after {
    content: '';

    @extend %flex-ac-jc;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-left: auto;

    border-radius: 50%;
    background-color: currentColor;
    background-image: url('/static/images/icons/arrow2.svg');
    @extend %background-rn-pc;
    background-size: 14px;
    transform: rotate(45deg);
    @extend %transition--100;
  }

  // li:nth-of-type(1) & {--background-url: url('https://test.vketov.space/images/projects/1/1.webp'); }
  // li:nth-of-type(2) & {--background-url: url("https://test.vketov.space/images/projects/2/1.webp"); }
  // li:nth-of-type(3) & {--background-url: url("https://test.vketov.space/images/projects/3/1.webp"); }
  // li:nth-of-type(4) & {--background-url: url("https://test.vketov.space/images/projects/4/1.webp"); }
  // li:nth-of-type(5) & {--background-url: url("https://test.vketov.space/images/projects/5/1.webp"); }
  // li:nth-of-type(6) & {--background-url: url("https://test.vketov.space/images/projects/6/1.webp"); }

  @include breakpoint-min($desktop) {
    gap: 40px;
    height: 340px;
  }
}

.project__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @extend %img-wh100-ofc;
  @extend %transition--200;

  .project__link:hover & {
    // transform: scale(1.15) rotate(2deg);
    transform: scale(1.15);
  }
}