.button {
  @extend %flex-ac-jc;
  height: 46px;
  width: 220px;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 8px;
  background-color: #272727;
  border: 1px solid transparent;
  @extend %transition--100;

  &--transparent {
    background-color: transparent;
    border-color: currentColor;
  }

  &.success {
    color: #272727;
    background-color: var(--color--corporeal, #f1eae4);
    border-color: var(--color--corporeal, #f1eae4);
  }

  &:hover {
    background-color: #666666;
    border-color: #666666;
  }
}
