.header__lang {
  @include breakpoint-max($desktop - 1) {
    display: none;
  }
}

.lang__list {
  @extend %flex-dc;
}

.lang__item {
  @extend %flex-ac-jc;
  gap: 5px;

  color: #272727;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
}
