%flex-ac {
  display: flex;
  align-items: center;
}
%flex-jsb {
  display: flex;
  justify-content: space-between;
}
%flex-afe {
  display: flex;
  align-items: flex-end;
}
%flex-ac-jc {
  @extend %flex-ac;
  justify-content: center;
}
%flex-ac-jsb {
  @extend %flex-ac;
  justify-content: space-between;
}
%flex-ac-jfs {
  @extend %flex-ac;
  justify-content: flex-start;
}
%flex-ac-jfe {
  @extend %flex-ac;
  justify-content: flex-end;
}
%flex-dc {
  display: flex;
  flex-direction: column;
}
%flex-ac-dc {
  @extend %flex-ac;
  flex-direction: column;
}
%flex-ac-jc-dc {
  @extend %flex-ac-jc;
  flex-direction: column;
}
%flex-jc {
  display: flex;
  justify-content: center;
}
%flex-ac-ww {
  @extend %flex-ac;
  flex-wrap: wrap;
}
%flex-ww {
  display: flex;
  flex-wrap: wrap;
}
%flex-jc-ww {
  @extend %flex-jc;
  flex-wrap: wrap;
}
%flex-ac-jc-ww {
  @extend %flex-ac-jc;
  flex-wrap: wrap;
}
%flex-100 {
  flex: 0 0 100%;
}
%flex-50 {
  flex: 0 0 50%;
}

%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
%fixed {
  position: fixed;
}
%absolute-tlrb {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
%fixed-tlrb {
  @extend %fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
%fixed-tl {
  @extend %fixed;
  top: 0;
  left: 0;
}

%pointer {
  cursor: pointer;
}
%border-radius {
  border-radius: var(--border-radius);
}
%border-radius-50 {
  border-radius: 50%;
}
%box-shadow {
  box-shadow: var(--box-shadow);
}
%box-shadow-main {
  box-shadow: var(--box-shadow--main);
}
%box-shadow-active {
  box-shadow: var(--box-shadow--active);
}
%currentColor {
  fill: currentColor;
}
%transition--20 {
  transition: var(--transition--20, 20ms linear);
}
%transition--50 {
  transition: var(--transition--50, 50ms linear);
}
%transition--100 {
  transition: var(--transition--100, 100ms linear);
}
%transition--200 {
  transition: var(--transition--200, 200ms linear);
}
%transition--300 {
  transition: var(--transition--300, 300ms linear);
}
%transition--400 {
  transition: var(--transition--400, 400ms linear);
}
%transition--500 {
  transition: var(--transition--500, 500ms linear);
}
%overflow-h {
  overflow: hidden;
}
%pvo {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

%background-rn-pc {
  background-repeat: no-repeat;
  background-position: center;
}

%img-wh100-ofc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

%cardSet {
  @extend %flex-ww;
  gap: var(--gap);
}

%cardSet__item {
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
}
