.feedback {
  position: relative;

  width: 100%;
  max-width: 500px;

  footer & {
    @include breakpoint-min($desktop) {
      flex-shrink: 0;
    }
  }
}

.feedback-form {
  @extend %flex-ac-dc;
  gap: 40px;
  padding: 38px 48px;

  border-radius: 15px;
  border: 1px solid #fff;
  @extend %transition--100;
  overflow: hidden;

  footer & {
    padding: 38px 20px;
  }

  &.isHidden {
    @extend %pvo;
  }
}

.feedback-form__cta {
  display: block;

  text-align: center;

  footer & {
    color: #fff;
  }
}

.feedback-form__input-wrap {
  @extend %flex-ac-dc;
  gap: 15px;
  width: 100%;
}
.feedback-form__input {
  @extend %flex-ac;
  gap: 10px;
  width: 100%;
  height: 44px;
  padding: 12px 22px;

  color: #414040;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;

  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--color--dark, #272727);
}

.feedback-form__msg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @extend %flex-ac-dc;
  gap: 20px;
  padding: 38px 48px;

  background-color: #fff;
  border-radius: 15px;

  & strong {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
  }

  & p {
    text-align: center;
    font-size: 14px;
  }

  footer & {
    color: var(--color--dark, #272727);
    padding: 38px 20px;
    max-height: 370px;

    @include breakpoint-min($desktop) {
      max-height: 400px;
    }
  }

  &.isHidden {
    display: none;
  }
}

.feedback-form__btn {
  footer & {
    background-color: transparent;
    border-color: currentColor;
  }
}
