.modal-feedback__backdrop {
  @extend %fixed-tl;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
  overflow: scroll;
  @extend %transition--100;

  .modal-feedback.isHidden & {
    @extend %pvo;
  }

  @include breakpoint-min-width-height($tablet, $tablet) {
    display: flex;
    align-items: center;
  }
}

.modal-feedback__content {
  width: 100%;
  margin: 0 auto;
  max-width: 500px;

 background-color: var(--color--white, #fff);
  @extend %border-radius;
  @extend %transition--100;

  .modal-form.isHidden & {
    transform: scale(0.8);
  }
}

[data-modal-btn-close] {
  margin-left: auto;
}


.modal__header {
  padding: 20px 20px 0;
}



.modal__middle {
  @extend %relative;

  // @extend %flex-dir-column;
  gap: 16px;
  // --padding: 32px;
  // padding: 0 var(--padding);
}
.modal__bottom {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.modal__bottom div {
  // @extend %flex-dir-column;
  gap: 4px;
}

#modalForm {
  margin-top: 20px;
}

#modalForm,
#modalForm label {
  // @extend %flex-dir-column;
  gap: 8px;
}

#modalForm {
  gap: 16px;
}

#modalForm input,
#modalForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;

  @extend %border-radius;
}

#modalForm textarea {
  padding: 12px;
  height: 80px;
  resize: none;
}

.tel--modal {
  margin-top: auto;
}

.modal__msg {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // @extend %flex-center-ja;
  flex-direction: column;
  gap: 20px;
  padding: 0 var(--padding);

  background-color: var(--color--white);
}
.modal__msg.isHidden {
  display: none;
}
.modal__msg--success strong {
  color: var(--color--green);
}
.modal__msg--error strong {
  color: red;
}
