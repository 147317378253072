.faq__container {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop) {
    flex-direction: row;
  }
}

.faq__title-wrap {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop) {
    flex: 0 0 480px;
  }
}
.faq__img {
  object-fit: cover;
  @extend %border-radius;

  @include breakpoint-max($desktop - 1) {
    display: none;
  }
}

.faq__list {
  @extend %flex-dc;
}
.faq__item {
  position: relative;
  @extend %flex-dc;

  @extend %transition--100;
  border-bottom: 1px solid #414040;

  &:first-of-type {
    border-top: 1px solid #414040;
  }
}

.faq__btn {
  position: relative;

  @extend %flex-ac;
  gap: 10px;
  padding: 20px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: start;

  @extend %transition--100;

  &::before, &::after  {
    content: '';

    background-color: currentColor;
    @extend %transition--100;
  }
  &::before {
    position: absolute;
    // top: 20px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);

    height: 19px;
    max-height: 19px;
    width: 1px;
  }
  &::after {
    width: 19px;
    height: 1;
    margin-left: auto;
  }

  &.active {
    color: #fff;
    background-color: var( --color--dark, #272727);

    &::before {
      max-height: 0px;
    }
  }

  @include breakpoint-min($desktop) {
    min-height: 60px;
    padding: 22px 20px;

    font-size: 16px;
  }
}
.faq__content {
  display: grid;
  grid-template-rows: 0fr;

  @extend %transition--100;

  &.active {
    grid-template-rows: 1fr;
  }
}
.faq__content-inner {
  padding: 0 20px;

  overflow: hidden;
}
.faq__text {
  font-size: 14px;

  @extend %transition--100;
  transition-delay: 50ms;
  opacity: 0.3;

  &:first-of-type { margin-top: 20px; }
  &:last-of-type { margin-bottom: 20px; }


  .active & {
    opacity: 1;
  }

  &--mb {
    margin-bottom: 16px;
  }
}