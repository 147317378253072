.header__contacts {
  @include breakpoint-max($desktop - 1) {
    display: none;
  }
}

.contacts__list {
  gap: var(--gap, 10px);

  .header__contacts & {
    @extend %flex-ac;
  }

  .footer__contacts & {
    @extend %flex-dc;
    gap: 10px;

    @include breakpoint-min($desktop) {
      flex-direction: row;
      gap: 44px;
    }
  }

  .burger-menu__contacts & {
    @extend %flex-ac-dc;
  }
}

.contact__link {
  color: #272727;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .burger-menu__contacts & {
    text-align: center;
  }

  .footer & {
    color: #fff;
  }

  @include breakpoint-min($desktop) {
    font-size: 16px;
  }
}
