.header {
  @extend %fixed-tl;
  width: 100%;
  height: 70px;

  z-index: 3;
  background-color: #fff;
}
.header__container {
  @extend %flex-ac;
  --gap: 25px;
  gap: var(--gap);

  height: 100%;
}
