.about__container {
  @extend %flex-dc;
  gap: 48px;

  @include breakpoint-min($desktop) {
    gap: 60px;
  }
}
.about__box {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop) {
    flex-direction: row;
  }
}

.about__img-wrap {
  position: relative;
  flex-shrink: 0;

  // &::before, &::after {
  //   content: '';

  //   position: absolute;
  //   z-index: 1;

  //   width: 15px;
  //   height: 15px;
  //   background-color: #fff;

  //   -webkit-mask: var(--mask-corner);
  //   mask: var(--mask-corner);
  //   transform: rotate(-90deg);
  // }

  // &::before {
  //   left: 0;
  //   bottom: 44px;
  // }
  // &::after {
  //   left: 140px;
  //   bottom: 0;
  // }

  @include breakpoint-min($desktop) {
    flex: 0 0 780px;
    max-width: 780px;
  }
}
.about__img {
  width: 100%;
  height: 214px;

  @extend %border-radius;

  @include breakpoint-min($desktop) {
    max-width: 780px;
    height: auto;
    max-height: 420px;

    object-fit: cover;
  }
}
.about__title {
  position: absolute;
  bottom: 0;
  left: 0;

  padding: 14px 46px 0 0;

  background: #fff;
  border-top-right-radius: 15px;

  &::before,
  &::after {
    content: '';

    position: absolute;
    z-index: 1;

    --border-radius: 15px;
    width: var(--border-radius, 15px);
    height: var(--border-radius, 15px);
    background-color: #fff;

    -webkit-mask: var(--mask-corner);
    mask: var(--mask-corner);
    transform: rotate(-90deg);
  }

  &::before {
    top: calc(-1 * var(--border-radius, 15px));
    left: 0;
  }
  &::after {
    right: calc(-1 * var(--border-radius, 15px));
    bottom: 0;
  }

  @include breakpoint-min($desktop) {
    padding: 25px 58px 0 0;
  }
}

.about__text-wrap {
  @extend %flex-dc;
  gap: 12px;
  padding: 20px;

  @extend %border-radius;
  background-color: var(--color--corporeal, #f1eae4);

  @include breakpoint-min($desktop) {
    flex: 0 0 380px;
  }
}
.about__text {
  font-size: 14px;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 16px;
  }
}

.facts__list {
  @extend %cardSet;
  justify-content: center;
  --gap: 30px;
}
.fact {
  @extend %cardSet__item;
  @extend %flex-dc;
  gap: 10px;
  --items: 2;

  @include breakpoint-min($desktop) {
    --items: 4;
    flex: 0 0 200px;
    max-width: 200px;
  }
}

.fact__value {
  font-size: 24px;
  font-weight: 500;

  @include breakpoint-min($desktop) {
    font-size: 36px;
  }
}

.fact__text {
  font-size: 14px;
  font-weight: 500;

  @include breakpoint-min($desktop) {
    font-size: 16px;
  }
}
