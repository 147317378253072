footer {
  margin-top: auto;
  padding: 48px 0;

  color: var(--color--white, #fff);
  background-color: var(--color--dark, #272727);

  @include breakpoint-min($desktop) {
    padding-top: 78px;
  }
}
.footer__top {
  margin-bottom: 20px;

  @include breakpoint-min($desktop) {
    margin-bottom: 36px;
  }
}
.footer__container {
  position: relative;
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop) {
    flex-direction: row-reverse;
    gap: 100px;
    padding-bottom: 44px;
  }
}

.contacts-box {
  @extend %flex-dc;
  gap: 40px;
}
.contacts-box__title {
  margin-bottom: 20px;
  color: #fff;
}
.contacts-box__msg {
  color: #fff;
  font-size: 14px;
}
.contacts-box__list {
  @extend %cardSet;
  justify-content: space-between;
  --gap: 40px;
}
.contacts-box__item {
  @extend %cardSet__item;
  @extend %flex-dc;
  flex-grow: 1;
  gap: 15px;

  @include breakpoint-min($mobileM) {
    --items: 2;
  }
  @include breakpoint-min($desktop) {
    --items: 1;
  }
}
.contacts-box__name {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 24px;
  }
}

.copyright {
  width: fit-content;
  margin: 0 auto;

  color: #414040;
  font-size: 16px;

  text-align: center;

  & span {
    text-transform: uppercase;
  }
}
