*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

a, input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: #0000;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

.link {
  color: inherit;
  text-decoration: none;
}

:root {
  --color--white: #fff;
  --color--dark: #272727;
  --color--corporeal: #f1eae4;
  --transition--20: 20ms linear;
  --transition--100: .1s linear;
  --transition--200: .2s linear;
  --transition--300: .3s linear;
  --transition--400: .4s linear;
  --transition--500: .5s linear;
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --text-indent: 40px;
  --border-radius: 15px;
  --mask-corner: radial-gradient(15px at 15px 15px, #0000 99%, #000) -15px -15px;
}

.faq__btn, .feedback-form__input, .header__container, .burger-menu__btn, .header__contacts .contacts__list, .hero__box, .feedback-form__msg, .feedback-form__input-wrap, .feedback-form, .burger-menu__contacts .contacts__list, .burger-menu__nav .nav__list, .header__nav .nav__list, .project__link:after, .btn-up, .btn-cross, .button, .lang__item {
  align-items: center;
  display: flex;
}

.project__link {
  align-items: flex-end;
  display: flex;
}

.project__link:after, .btn-up, .btn-cross, .button, .lang__item {
  justify-content: center;
}

.header__nav .nav__list {
  justify-content: space-between;
}

.reviews__container, .faq__item, .faq__list, .faq__title-wrap, .faq__container, .components__container, .workshop__text-wrap, .workshop__box, .technologies__text-wrap, .technologies__container, .fact, .about__text-wrap, .about__box, .about__container, .contacts-box__item, .contacts-box, .footer__container, .contacts__wrap, .burger-menu__container, .footer__contacts .contacts__list, .lang__list, main, body {
  flex-direction: column;
  display: flex;
}

.hero__box, .feedback-form__msg, .feedback-form__input-wrap, .feedback-form, .burger-menu__contacts .contacts__list, .burger-menu__nav .nav__list {
  flex-direction: column;
}

.facts__list, .projects__list, .contacts-box__list {
  flex-wrap: wrap;
  display: flex;
}

.modal__middle, .burger-menu__btn, main, body, .lds-roller {
  position: relative;
}

.modal__msg, .burger-menu__btn .burger-line, .btn-cross:before, .btn-cross:after, .lds-roller div:after, .visually-hidden {
  position: absolute;
}

.burger-menu, .modal-feedback__backdrop, .header {
  position: fixed;
}

.modal-feedback__backdrop, .header {
  top: 0;
  left: 0;
}

.btn-cross, button, a {
  cursor: pointer;
}

.faq__img, .components__video, .components__img, .workshop__img, .workshop__box, .technologies__video, .technologies__img, .about__text-wrap, .about__img, .project__link, .hero__box, #modalForm input, #modalForm textarea, .modal-feedback__content, .burger-menu__btn .burger-line, .btn-cross:before, .btn-cross:after {
  border-radius: var(--border-radius);
}

.lds-roller div:after {
  border-radius: 50%;
}

.faq__text, .faq__content, .faq__btn:before, .faq__btn:after, .faq__btn, .faq__item, .project__link:after, .modal-feedback__content, .modal-feedback__backdrop, .feedback-form, .button, .nav__link, .hover-line:before {
  transition: var(--transition--100, .1s linear);
}

.project__img, .burger-menu, main, body {
  transition: var(--transition--200, .2s linear);
}

body.freeze, body.isLoading, body.modalIsOpen, body.burgerMenuIsOpen, .visually-hidden {
  overflow: hidden;
}

.modal-feedback.isHidden .modal-feedback__backdrop, .feedback-form.isHidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.project__link:after {
  background-position: center;
  background-repeat: no-repeat;
}

.components__video, .components__img, .workshop__img, .technologies__video, .technologies__img, .project__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.facts__list, .projects__list, .contacts-box__list {
  gap: var(--gap);
}

.fact, .project, .contacts-box__item {
  flex-basis: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
}

.backdrop.loader__backdrop {
  align-items: center;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: #f0f0f0;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  background-color: var(--color--accent, #9cfe01);
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

body {
  color: var(--color--dark, #272727);
  background-color: #fff;
  min-height: 100vh;
  overflow-y: scroll;
}

main {
  contain: paint;
  flex-grow: 1;
  padding-top: 70px;
}

footer {
  margin-top: auto;
}

svg {
  pointer-events: none;
}

.section {
  padding-bottom: 48px;
}

@media (width >= 1280px) {
  .section {
    padding-bottom: 60px;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 14px;
}

@media (width >= 375px) {
  .container {
    max-width: 375px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
    padding: 0 50px;
  }
}

.title {
  color: #272727;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

@media (width >= 1280px) {
  .title {
    font-size: 36px;
  }
}

.hover-line {
  position: relative;
}

.hover-line:before {
  content: "";
  background-color: currentColor;
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.hover-line:hover:before {
  width: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  text-transform: uppercase;
  font-family: Inter;
  font-weight: 800;
  line-height: normal;
}

.logo svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.header__logo {
  color: var(--color--dark, #272727);
  width: 160px;
  margin-right: auto;
  font-size: 22px;
}

.footer__logo {
  color: var(--color--white, #fff);
  text-align: center;
  width: 200px;
  margin: 0 auto;
  font-size: 36px;
  display: block;
}

@media (width >= 1280px) {
  .footer__logo {
    text-align: end;
    position: absolute;
    bottom: 0;
    right: 50px;
  }
}

@media (width <= 1279px) {
  .header__nav {
    display: none;
  }
}

.nav__list {
  gap: var(--gap, 25px);
}

.nav__link {
  color: #272727;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
}

@media (width <= 1279px) {
  .header__lang {
    display: none;
  }
}

.lang__item {
  color: #272727;
  gap: 5px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
}

@media (width <= 1279px) {
  .header__contacts {
    display: none;
  }
}

.contacts__list {
  gap: var(--gap, 10px);
}

.footer__contacts .contacts__list {
  gap: 10px;
}

@media (width >= 1280px) {
  .footer__contacts .contacts__list {
    flex-direction: row;
    gap: 44px;
  }
}

.contact__link {
  color: #272727;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.burger-menu__contacts .contact__link {
  text-align: center;
}

.footer .contact__link {
  color: #fff;
}

@media (width >= 1280px) {
  .contact__link {
    font-size: 16px;
  }
}

.button {
  color: #fff;
  background-color: #272727;
  border: 1px solid #0000;
  border-radius: 8px;
  width: 220px;
  height: 46px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button--transparent {
  background-color: #0000;
  border-color: currentColor;
}

.button.success {
  color: #272727;
  background-color: var(--color--corporeal, #f1eae4);
  border-color: var(--color--corporeal, #f1eae4);
}

.button:hover {
  background-color: #666;
  border-color: #666;
}

.btn-cross {
  background-color: #0000;
  width: 24px;
  height: 24px;
  position: relative;
}

.btn-cross:before, .btn-cross:after {
  content: "";
  background-color: #000;
  width: 16px;
  height: 2px;
  margin: 0;
}

.btn-cross:before {
  transform: rotate(-45deg);
}

.btn-cross:after {
  transform: rotate(45deg);
}

.btn-up {
  color: var(--color--white);
  background-color: var(--color--black);
  border: 2px solid;
  border-color: var(--color--white);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  transition: all .3s linear;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-up.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.btn-up:hover, .btn-up:focus {
  transform: scale(1.1);
}

.burger-menu__btn {
  color: var(--color--dark, #272727);
  background-color: #0000;
  justify-content: flex-end;
  width: 32px;
  height: 100%;
}

body.burgerMenuIsOpen .burger-menu__btn {
  z-index: 3;
}

@media (width >= 1280px) {
  .burger-menu__btn {
    display: none;
  }
}

.burger-menu__btn .burger-line {
  pointer-events: none;
  background-color: currentColor;
  width: 24px;
  height: 2px;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  width: 32px;
  transform: translateY(-3px);
}

.burger-line:last-child {
  transform: translateY(3px);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:first-child {
  width: 24px;
  transform: rotate(-45deg);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.burger-menu {
  z-index: 1;
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  top: 0;
  right: 0;
}

.burger-menu.isHidden {
  top: -101%;
}

.burger-menu.isHidden button, .burger-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
  display: none;
}

@media (width >= 1280px) {
  .burger-menu {
    display: none;
  }
}

.burger-menu__container {
  --gap: 25px;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.contacts__wrap {
  gap: var(--gap, 25px);
  padding-top: var(--gap, 25px);
  margin-top: var(--gap, 25px);
  border-top: 1px solid var(--color--corporeal, #f1eae4);
}

.contacts__wrap h2 {
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
}

.header {
  z-index: 3;
  background-color: #fff;
  width: 100%;
  height: 70px;
}

.header__container {
  --gap: 25px;
  gap: var(--gap);
  height: 100%;
}

footer {
  color: var(--color--white, #fff);
  background-color: var(--color--dark, #272727);
  margin-top: auto;
  padding: 48px 0;
}

@media (width >= 1280px) {
  footer {
    padding-top: 78px;
  }
}

.footer__top {
  margin-bottom: 20px;
}

@media (width >= 1280px) {
  .footer__top {
    margin-bottom: 36px;
  }
}

.footer__container {
  gap: 20px;
  position: relative;
}

@media (width >= 1280px) {
  .footer__container {
    flex-direction: row-reverse;
    gap: 100px;
    padding-bottom: 44px;
  }
}

.contacts-box {
  gap: 40px;
}

.contacts-box__title {
  color: #fff;
  margin-bottom: 20px;
}

.contacts-box__msg {
  color: #fff;
  font-size: 14px;
}

.contacts-box__list {
  --gap: 40px;
  justify-content: space-between;
}

.contacts-box__item {
  flex-grow: 1;
  gap: 15px;
}

@media (width >= 375px) {
  .contacts-box__item {
    --items: 2;
  }
}

@media (width >= 1280px) {
  .contacts-box__item {
    --items: 1;
  }
}

.contacts-box__name {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

@media (width >= 1280px) {
  .contacts-box__name {
    font-size: 24px;
  }
}

.copyright {
  color: #414040;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
}

.copyright span {
  text-transform: uppercase;
}

.feedback {
  width: 100%;
  max-width: 500px;
  position: relative;
}

@media (width >= 1280px) {
  footer .feedback {
    flex-shrink: 0;
  }
}

.feedback-form {
  border: 1px solid #fff;
  border-radius: 15px;
  gap: 40px;
  padding: 38px 48px;
  overflow: hidden;
}

footer .feedback-form {
  padding: 38px 20px;
}

.feedback-form__cta {
  text-align: center;
  display: block;
}

footer .feedback-form__cta {
  color: #fff;
}

.feedback-form__input-wrap {
  gap: 15px;
  width: 100%;
}

.feedback-form__input {
  color: #414040;
  border: 1px solid var(--color--dark, #272727);
  background: #fff;
  border-radius: 8px;
  gap: 10px;
  width: 100%;
  height: 44px;
  padding: 12px 22px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}

.feedback-form__msg {
  background-color: #fff;
  border-radius: 15px;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 38px 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.feedback-form__msg strong {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.feedback-form__msg p {
  text-align: center;
  font-size: 14px;
}

footer .feedback-form__msg {
  color: var(--color--dark, #272727);
  max-height: 370px;
  padding: 38px 20px;
}

@media (width >= 1280px) {
  footer .feedback-form__msg {
    max-height: 400px;
  }
}

.feedback-form__msg.isHidden {
  display: none;
}

footer .feedback-form__btn {
  background-color: #0000;
  border-color: currentColor;
}

.modal-feedback__backdrop {
  z-index: 3;
  background-color: #000000e6;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
}

@media (width >= 768px) and (height >= 768px) {
  .modal-feedback__backdrop {
    align-items: center;
    display: flex;
  }
}

.modal-feedback__content {
  background-color: var(--color--white, #fff);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.modal-form.isHidden .modal-feedback__content {
  transform: scale(.8);
}

[data-modal-btn-close] {
  margin-left: auto;
}

.modal__header {
  padding: 20px 20px 0;
}

.modal__middle {
  gap: 16px;
}

.modal__bottom {
  justify-content: space-between;
  padding: 32px;
  display: flex;
}

.modal__bottom div {
  gap: 4px;
}

#modalForm {
  margin-top: 20px;
}

#modalForm, #modalForm label {
  gap: 8px;
}

#modalForm {
  gap: 16px;
}

#modalForm input, #modalForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;
}

#modalForm textarea {
  resize: none;
  height: 80px;
  padding: 12px;
}

.tel--modal {
  margin-top: auto;
}

.modal__msg {
  padding: 0 var(--padding);
  background-color: var(--color--white);
  flex-direction: column;
  gap: 20px;
  inset: 0;
}

.modal__msg.isHidden {
  display: none;
}

.modal__msg--success strong {
  color: var(--color--green);
}

.modal__msg--error strong {
  color: red;
}

.hero__box {
  background-color: #0000;
  background-image: linear-gradient(0deg, #00000075 0% 100%), url("https://test.vketov.space/images/homepage/hero.webp");
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  gap: 20px;
  min-height: 230px;
  padding: 40px 20px;
  position: relative;
}

@media (width <= 1279px) {
  .hero__box {
    margin-bottom: 66px;
  }
}

@media (width >= 1280px) {
  .hero__box {
    background-position: center, 0 -80px;
    gap: 35px;
    padding: 100px 150px;
  }
}

.hero__title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

@media (width >= 1280px) {
  .hero__title {
    font-size: 40px;
  }
}

.hero__cta {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

@media (width >= 1280px) {
  .hero__cta {
    font-size: 24px;
  }
}

@media (width <= 1279px) {
  .hero__button {
    position: absolute;
    bottom: -66px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (width >= 1280px) {
  .hero__button {
    background-color: #0000;
    border-color: currentColor;
  }
}

.projects__title {
  margin-bottom: 15px;
}

.projects__msg {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: block;
}

@media (width >= 1280px) {
  .projects__msg {
    margin-bottom: 24px;
    font-size: 16px;
  }
}

.projects__list {
  --gap: 10px;
}

@media (width >= 1280px) {
  .projects__list {
    --gap: 20px;
  }

  .project {
    --items: 3;
  }
}

.project__link {
  color: #fff;
  gap: 20px;
  width: 100%;
  height: 200px;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  overflow: hidden;
}

.project__link:after {
  content: "";
  background-color: currentColor;
  background-image: url("arrow2.335c4878.svg");
  background-size: 14px;
  border-radius: 50%;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  transform: rotate(45deg);
}

@media (width >= 1280px) {
  .project__link {
    gap: 40px;
    height: 340px;
  }
}

.project__img {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.project__link:hover .project__img {
  transform: scale(1.15);
}

.about__container {
  gap: 48px;
}

@media (width >= 1280px) {
  .about__container {
    gap: 60px;
  }
}

.about__box {
  gap: 20px;
}

@media (width >= 1280px) {
  .about__box {
    flex-direction: row;
  }
}

.about__img-wrap {
  flex-shrink: 0;
  position: relative;
}

@media (width >= 1280px) {
  .about__img-wrap {
    flex: 0 0 780px;
    max-width: 780px;
  }
}

.about__img {
  width: 100%;
  height: 214px;
}

@media (width >= 1280px) {
  .about__img {
    object-fit: cover;
    max-width: 780px;
    height: auto;
    max-height: 420px;
  }
}

.about__title {
  background: #fff;
  border-top-right-radius: 15px;
  padding: 14px 46px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.about__title:before, .about__title:after {
  content: "";
  z-index: 1;
  --border-radius: 15px;
  width: var(--border-radius, 15px);
  height: var(--border-radius, 15px);
  -webkit-mask: var(--mask-corner);
  -webkit-mask: var(--mask-corner);
  mask: var(--mask-corner);
  background-color: #fff;
  position: absolute;
  transform: rotate(-90deg);
}

.about__title:before {
  top: calc(-1 * var(--border-radius, 15px));
  left: 0;
}

.about__title:after {
  right: calc(-1 * var(--border-radius, 15px));
  bottom: 0;
}

@media (width >= 1280px) {
  .about__title {
    padding: 25px 58px 0 0;
  }
}

.about__text-wrap {
  background-color: var(--color--corporeal, #f1eae4);
  gap: 12px;
  padding: 20px;
}

@media (width >= 1280px) {
  .about__text-wrap {
    flex: 0 0 380px;
  }
}

.about__text {
  font-size: 14px;
  line-height: normal;
}

@media (width >= 1280px) {
  .about__text {
    font-size: 16px;
  }
}

.facts__list {
  --gap: 30px;
  justify-content: center;
}

.fact {
  --items: 2;
  gap: 10px;
}

@media (width >= 1280px) {
  .fact {
    --items: 4;
    flex: 0 0 200px;
    max-width: 200px;
  }
}

.fact__value {
  font-size: 24px;
  font-weight: 500;
}

@media (width >= 1280px) {
  .fact__value {
    font-size: 36px;
  }
}

.fact__text {
  font-size: 14px;
  font-weight: 500;
}

@media (width >= 1280px) {
  .fact__text {
    font-size: 16px;
  }
}

.technologies__container {
  gap: 20px;
}

@media (width <= 1279px) {
  .technologies__container {
    padding-top: 80px;
    position: relative;
  }
}

@media (width >= 1280px) {
  .technologies__container {
    flex-direction: row;
  }
}

@media (width <= 1279px) {
  .technologies__title {
    position: absolute;
    top: 0;
    left: 14px;
  }
}

.technologies__img-wrap {
  grid-template-rows: 150px 144px;
  grid-template-columns: auto 122px;
  gap: 10px;
  display: grid;
}

@media (width >= 1280px) {
  .technologies__img-wrap {
    grid-template-rows: 220px auto;
    grid-template-columns: repeat(2, 1fr);
  }

  .technologies__img:first-of-type {
    grid-area: 2 / 2 / 3 / 3;
  }

  .technologies__img:nth-of-type(2) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.technologies__video-wrap {
  grid-area: 1 / 1 / 2 / 3;
  position: relative;
}

@media (width >= 1280px) {
  .technologies__video-wrap {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.technologies__text-wrap {
  gap: 20px;
}

@media (width >= 1280px) {
  .technologies__text-wrap {
    flex: 0 0 460px;
    max-width: 460px;
  }
}

.technologies__text {
  font-size: 14px;
  line-height: normal;
}

@media (width >= 1280px) {
  .technologies__text {
    font-size: 16px;
  }
}

@media (width <= 1279px) {
  .technologies__button {
    margin: 0 auto;
  }
}

.workshop__box {
  background-color: var(--color--corporeal, #f1eae4);
  gap: 20px;
  padding: 20px;
}

@media (width <= 1279px) {
  .workshop__box {
    flex-direction: column-reverse;
    padding-top: 100px;
    position: relative;
  }
}

@media (width >= 1280px) {
  .workshop__box {
    flex-direction: row;
  }
}

@media (width <= 1279px) {
  .workshop__title {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.workshop__text-wrap {
  gap: 20px;
}

@media (width >= 1280px) {
  .workshop__text-wrap {
    gap: 24px;
  }
}

.workshop__text {
  font-size: 14px;
}

@media (width >= 1280px) {
  .workshop__text {
    font-size: 16px;
  }
}

.workshop__img-wrap {
  height: 210px;
}

@media (width >= 1280px) {
  .workshop__img-wrap {
    flex: 0 0 780px;
    height: 434px;
  }
}

.components__container {
  gap: 20px;
  position: relative;
}

@media (width <= 1279px) {
  .components__container {
    padding-bottom: 66px;
  }
}

.components__text-wrap {
  gap: 15px;
  display: grid;
}

@media (width >= 1280px) {
  .components__text-wrap {
    grid-template-rows: auto auto;
    grid-template-columns: 540px auto;
  }

  .components__title {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.components__text {
  font-size: 14px;
}

@media (width >= 1280px) {
  .components__text {
    text-align: right;
    grid-area: 2 / 2 / 3 / 3;
    font-size: 16px;
  }
}

@media (width <= 1279px) {
  .components__button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (width >= 1280px) {
  .components__button {
    grid-area: 1 / 2 / 2 / 3;
    margin-left: auto;
  }
}

.components__img-wrap {
  grid-template-rows: repeat(3, 150px);
  grid-template-columns: 122px auto 122px;
  gap: 12px;
  display: grid;
}

@media (width >= 1280px) {
  .components__img-wrap {
    grid-template-rows: repeat(2, 250px);
    grid-template-columns: 540px 200px auto 200px;
    gap: 20px;
  }
}

.components__img:first-of-type {
  grid-area: 2 / 1 / 3 / 2;
}

.components__img:nth-of-type(2) {
  grid-area: 2 / 2 / 3 / 4;
}

.components__img:nth-of-type(3) {
  grid-area: 3 / 1 / 4 / 3;
}

.components__img:nth-of-type(4) {
  grid-area: 3 / 3 / 4 / 4;
}

@media (width >= 1280px) {
  .components__img:first-of-type {
    grid-area: 1 / 2 / 2 / 3;
  }

  .components__img:nth-of-type(2) {
    grid-area: 1 / 3 / 2 / 5;
  }

  .components__img:nth-of-type(3) {
    grid-area: 2 / 2 / 3 / 4;
  }

  .components__img:nth-of-type(4) {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.components__video-wrap {
  grid-area: 1 / 1 / 2 / 4;
  position: relative;
}

@media (width >= 1280px) {
  .components__video-wrap {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.faq__container {
  gap: 20px;
}

@media (width >= 1280px) {
  .faq__container {
    flex-direction: row;
  }
}

.faq__title-wrap {
  gap: 20px;
}

@media (width >= 1280px) {
  .faq__title-wrap {
    flex: 0 0 480px;
  }
}

.faq__img {
  object-fit: cover;
}

@media (width <= 1279px) {
  .faq__img {
    display: none;
  }
}

.faq__item {
  border-bottom: 1px solid #414040;
  position: relative;
}

.faq__item:first-of-type {
  border-top: 1px solid #414040;
}

.faq__btn {
  text-align: start;
  gap: 10px;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.faq__btn:before, .faq__btn:after {
  content: "";
  background-color: currentColor;
}

.faq__btn:before {
  width: 1px;
  height: 19px;
  max-height: 19px;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.faq__btn:after {
  width: 19px;
  height: 1px;
  margin-left: auto;
}

.faq__btn.active {
  color: #fff;
  background-color: var(--color--dark, #272727);
}

.faq__btn.active:before {
  max-height: 0;
}

@media (width >= 1280px) {
  .faq__btn {
    min-height: 60px;
    padding: 22px 20px;
    font-size: 16px;
  }
}

.faq__content {
  grid-template-rows: 0fr;
  display: grid;
}

.faq__content.active {
  grid-template-rows: 1fr;
}

.faq__content-inner {
  padding: 0 20px;
  overflow: hidden;
}

.faq__text {
  opacity: .3;
  font-size: 14px;
  transition-delay: 50ms;
}

.faq__text:first-of-type {
  margin-top: 20px;
}

.faq__text:last-of-type {
  margin-bottom: 20px;
}

.active .faq__text {
  opacity: 1;
}

.faq__text--mb {
  margin-bottom: 16px;
}

.reviews__container {
  gap: 20px;
}

.reviews__cta {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.reviews__iframe {
  background: #d9d9d9;
  height: 352px;
}

@media (width >= 1280px) {
  .reviews__iframe {
    height: 178px;
  }
}
/*# sourceMappingURL=index.89ab832d.css.map */
