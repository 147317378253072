.btn-up {
  position: fixed;
  bottom: 20px;
  right: 20px;

  @extend %flex-ac-jc;

  width: 48px;
  height: 48px;

  color: var(--color--white);
  // color: var(--color--black);

  // background-color: var(--color--green);
  // background-color: var(--color--white);
  // background-color: transparent;
  background-color: var(--color--black);
  border: 2px solid;
  // border-color: var(--color--green);
  // border-color: var(--color--black);
  border-color: var(--color--white);
  border-radius: 50%;
  transition: all 300ms linear;
  
  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.btn-up:hover, .btn-up:focus {
  // color: var(--color--green);
  transform: scale(1.1);
}

// .btn-up__icon {
//   width: 20px;
//   height: 20px;
// }