@import '/src/common/scss/reset.scss';
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
@import '/src/components/loader/loader.scss';

// * { outline: 1px solid teal; }

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}
body {
  @extend %relative;

  @extend %flex-dc;
  min-height: 100vh;

  color: var(--color--dark, #272727);

  background-color: #fff;
  @extend %transition--200;
  overflow-y: scroll;

  &.freeze,
  &.isLoading,
  &.modalIsOpen,
  &.burgerMenuIsOpen {
    @extend %overflow-h;
  }
}
main {
  @extend %relative;
  flex-grow: 1;
  @extend %flex-dc;
  padding-top: 70px;

  @extend %transition--200;
  contain: paint;
}
footer {
  margin-top: auto;
}

svg {
  pointer-events: none;
}
button,
a {
  @extend %pointer;
}

.section {
  padding-bottom: 48px;

  @include breakpoint-min($desktop) {
    padding-bottom: 60px;
  }
}

.container {
  width: 100%;
  padding: 0 14px;
  margin: 0 auto;

  @include breakpoint-min($mobileM) {
    max-width: $mobileM;
  }
  @include breakpoint-min($desktop) {
    max-width: $desktop;
    padding: 0 50px;
  }
}

.title {
  color: #272727;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 36px;
  }
}

.hover-line {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    bottom: -1px;
    left: 0;

    width: 0%;
    height: 1px;

    background-color: currentColor;
    @extend %transition--100;
  }

  &:hover::before {
    width: 100%;
  }
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

@import '/src/components/logo/logo.scss';
@import '/src/components/nav/nav.scss';
@import '/src/components/lang/lang.scss';
@import '/src/components/contacts/contacts.scss';
@import '/src/components/button/button.scss';
@import '/src/components/btn-cross/btn-cross.scss';
@import '/src/components/btn-up/btn-up.scss';
@import '/src/components/burger-menu/burger-menu.scss';
@import '/src/components/header/header.scss';
@import '/src/components/footer/footer.scss';
@import '/src/components/feedback-form/feedback-form.scss';
@import '/src/components/modal-feedback/modal-feedback.scss';
