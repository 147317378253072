.technologies__container {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-max($desktop - 1) {
    position: relative;
    padding-top: 80px;
  }
  @include breakpoint-min($desktop) {
    flex-direction: row;
  }
}

.technologies__title {
  @include breakpoint-max($desktop - 1) {
    position: absolute;
    top: 0;
    left: 14px;
  }
}

.technologies__img-wrap {
  display: grid;
  grid-template-columns: auto 122px;
  grid-template-rows: 150px 144px;
  gap: 10px;

  @include breakpoint-min($desktop) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 220px auto;
  }
}
.technologies__img {
  @extend %img-wh100-ofc;
  @extend %border-radius;

  @include breakpoint-min($desktop) {
    &:nth-of-type(1) {
      grid-area: 2 / 2 / 3 / 3;
    }
    &:nth-of-type(2) {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
}
.technologies__video-wrap {
  position: relative;
  grid-area: 1 / 1 / 2 / 3;

  @include breakpoint-min($desktop) {
    grid-area: 1 / 2 / 2 / 3;
  }
}
.technologies__video {
  @extend %img-wh100-ofc;
  @extend %border-radius;
}

.technologies__text-wrap {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop ) {
    flex: 0 0 460px;
    max-width: 460px;
  }
}
.technologies__text {
  font-size: 14px;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 16px;
  }
}

.technologies__button {
  @include breakpoint-max($desktop - 1) {
    margin: 0 auto;
  }
}