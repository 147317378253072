.components__container {
  position: relative;
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-max($desktop - 1) {
    padding-bottom: 66px;
  }
}

.components__text-wrap {
  display: grid;
  gap: 15px;

  @include breakpoint-min($desktop) {
    grid-template-columns: 540px auto;
    grid-template-rows: auto auto;
  }
}
.components__title {
  @include breakpoint-min($desktop) {
    grid-area: 1 / 1 / 3 / 2;
  }
}
.components__text {
  font-size: 14px;

  @include breakpoint-min($desktop) {
    grid-area: 2 / 2 / 3 / 3;

    font-size: 16px;
    text-align: right;
  }
}
.components__button {
  @include breakpoint-max($desktop - 1) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @include breakpoint-min($desktop) {
    margin-left: auto;
    grid-area: 1 / 2 / 2 / 3;
  }
}

.components__img-wrap {
  display: grid;
  grid-template-columns: 122px auto 122px;
  grid-template-rows: repeat(3, 150px);
  gap: 12px;

  @include breakpoint-min($desktop) {
    grid-template-columns: 540px 200px auto 200px;
    grid-template-rows: repeat(2, 250px);
    gap: 20px;
  }
}
.components__img {
  @extend %img-wh100-ofc;
  @extend %border-radius;

  &:nth-of-type(1) {
    grid-area: 2 / 1 / 3 / 2;
  }
  &:nth-of-type(2) {
    grid-area: 2 / 2 / 3 / 4;
  }
  &:nth-of-type(3) {
    grid-area: 3 / 1 / 4 / 3;
  }
  &:nth-of-type(4) {
    grid-area: 3 / 3 / 4 / 4;
  }

  @include breakpoint-min($desktop) {
    &:nth-of-type(1) {
      grid-area: 1 / 2 / 2 / 3;
    }
    &:nth-of-type(2) {
      grid-area: 1 / 3 / 2 / 5; 
    }
    &:nth-of-type(3) {
      grid-area: 2 / 2 / 3 / 4;
    }
    &:nth-of-type(4) {
      grid-area: 2 / 4 / 3 / 5;
    }
  }
}
.components__video-wrap {
  position: relative;
  
  grid-area: 1 / 1 / 2 / 4; 

  @include breakpoint-min($desktop) {
    grid-area: 1 / 1 / 3 / 2;
  }
}
.components__video {
  @extend %img-wh100-ofc;
  @extend %border-radius;
}

