.logo {
  font-family: Inter;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  & svg {
    width: 100%;
    height: 100%;

    fill: currentColor;
  }
}

.header__logo {
  margin-right: auto;
  width: 160px;

  color: var(--color--dark, #272727);
  font-size: 22px;
}

.footer__logo {
  display: block;
  margin: 0 auto;
  width: 200px;

  color: var(--color--white, #fff);
  font-size: 36px;
  text-align: center;

  @include breakpoint-min($desktop) {
    position: absolute;
    bottom: 0;
    right: 50px;

    text-align: end;
  }
}
