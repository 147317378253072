.btn-cross {
  position: relative;

  @extend %flex-ac-jc;
  width: 24px;
  height: 24px;

  background-color: transparent;
  @extend %pointer;

  &::before,
  &::after {
    content: '';
    @extend %absolute;

    width: 16px;
    height: 2px;
    margin: 0;

    background-color: black;
    @extend %border-radius;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}
