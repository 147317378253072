.reviews__container {
  @extend %flex-dc;
  gap: 20px;
}

.reviews__cta {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.reviews__iframe {
  height: 352px;
  background: #d9d9d9;

  @include breakpoint-min($desktop) {
    height: 178px;
  }
}
