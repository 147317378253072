:root {
  // ===== colors =====
  --color--white: #fff;
  --color--dark: #272727;
  --color--corporeal: #f1eae4;
  // background-color: var(--color--white, #fff);
  // background-color: var( --color--dark, #272727);
  // background-color: var( --color--corporeal, #F1EAE4);
  // ===== transition =====
  --transition--20: 20ms linear;
  --transition--100: 100ms linear;
  --transition--200: 200ms linear;
  --transition--300: 300ms linear;
  --transition--400: 400ms linear;
  --transition--500: 500ms linear;
  // ===== decor =====
  // ===== other =====
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --text-indent: 40px;
  --border-radius: 15px;
  --mask-corner: radial-gradient(15px at 15px 15px, #0000 99%, #000) -15px -15px;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1280px;
$desktopL: 1440px;
$desktopXL: 1920px;
// ===== breakpoints =====
