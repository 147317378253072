.workshop__box {
  @extend %flex-dc;
  gap: 20px;
  padding: 20px;

  @extend %border-radius;
  background-color: var(--color--corporeal, #f1eae4);

  @include breakpoint-max($desktop - 1) {
    position: relative;
    flex-direction: column-reverse;
    padding-top: 100px;
  }

  @include breakpoint-min($desktop) {
    flex-direction: row;
  }
}

.workshop__title {
  @include breakpoint-max($desktop - 1) {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
.workshop__text-wrap {
  @extend %flex-dc;
  gap: 20px;

  @include breakpoint-min($desktop) {
    gap: 24px;
  }
}
.workshop__text {
  font-size: 14px;

  @include breakpoint-min($desktop) {
    font-size: 16px;
  }
}

.workshop__img-wrap {
  height: 210px;

  @include breakpoint-min($desktop) {
    flex: 0 0 780px;
    height: 434px;
  }
}
.workshop__img {
  @extend %img-wh100-ofc;
  @extend %border-radius;
}