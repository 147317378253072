@import '/src/components/burger-menu/burger-menu-btn.scss';

.burger-menu {
  @extend %fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background-color: #fff;
  @extend %transition--200;
  z-index: 1;

  &.isHidden {
    top: -101%;

    button,
    a {
      display: none;
      pointer-events: none;
      visibility: hidden;
    }
  }

  @include breakpoint-min($desktop) {
    display: none;
  }
}
.burger-menu__container {
  @extend %flex-dc;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  --gap: 25px;
}

.contacts__wrap {
  @extend %flex-dc;
  gap: var(--gap, 25px);
  padding-top: var(--gap, 25px);
  margin-top: var(--gap, 25px);

  border-top: 1px solid var( --color--corporeal, #F1EAE4);
}

.contacts__wrap {
  h2 {
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
  }
}
