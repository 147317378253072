
.hero__box {
  position: relative;

  @extend %flex-ac-dc;
  gap: 20px;
  min-height: 230px;
  padding: 40px 20px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.46) 0%, rgba(0, 0, 0, 0.46) 100%), url("https://test.vketov.space/images/homepage/hero.webp");
  background-repeat: no-repeat;
  background-size: cover;
  @extend %border-radius;

  @include breakpoint-max($desktop - 1) {
    margin-bottom: 66px;
  }
  @include breakpoint-min($desktop) {
    gap: 35px;
    padding: 100px 150px;

    background-position: center, top -80px left 0;
  }
}

.hero__title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 40px;
  }
}

.hero__cta {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  @include breakpoint-min($desktop) {
    font-size: 24px;
  }
}

.hero__button {
  @include breakpoint-max($desktop - 1) {
    position: absolute;
    bottom: -66px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include breakpoint-min($desktop) {
    background-color: transparent;
    border-color: currentColor;
  }
}