.header__nav {
  @include breakpoint-max($desktop - 1) {
    display: none;
  }
}

.nav__list {
  gap: var(--gap, 25px);

  .header__nav & {
    @extend %flex-ac-jsb;
  }

  .burger-menu__nav & {
    @extend %flex-ac-dc;
  }
}

.nav__link {
  color: #272727;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;

  @extend %transition--100;
}
